import './Parameet.css'

export default function Parameet() {
  return (
    <div className="container">
      <img
        src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/image%20(10)-Bf8YqZKPEegOE79gNErQcRfUdIu0qr.png"
        alt="Parameet Background"
        layout="fill"
        quality={100}
        className="parameet-img"
      />
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-70 z-10"></div>
      <div className="container mx-auto px-4 z-20 relative">
        <main className="text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-8 text-white shadow-text">
            Welcome to the Parameet Experience!
          </h1>
          <div className="bg-white bg-opacity-90 rounded-3xl p-6 mb-8 shadow-lg">
            <iframe
              src="https://d3ivlpawtjsxx9.cloudfront.net/1.mp4"
              title="Parameet Introduction Video"
              allowFullScreen
              className="video"
            ></iframe>
          </div>
          <a
            href="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"
            className="inline-block px-8 py-4 bg-primary text-white font-bold rounded-full text-lg transition-transform hover:scale-105 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
          >
            Join Parameet Now!
          </a>
          <p className="mt-8 text-lg italic text-yellow-300 shadow-text">
            Did you know? Parrots are among the most intelligent birds, capable
            of vocal learning, problem-solving, and even dancing to music!
          </p>
        </main>
      </div>
    </div>
  )
}
